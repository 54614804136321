import { lazy, Suspense } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import browserHistory from 'utils/browser-utils';

const ProductsPage = lazy(() => import('views/products'));

const Routes: React.FC = () => {
    return (
        <Router history={browserHistory}>
            <Suspense fallback={<></>}>
                <Switch>
                    <Route path="/:category" component={ProductsPage} />
                    <Redirect to="/All" />
                </Switch>
            </Suspense>
        </Router>
    );
};

export default Routes;
